import Vue from 'vue/dist/vue.esm.js';
import region from '../../data/region.json'
import "@babel/polyfill";
import axios from 'axios'
import VueAxios from 'vue-axios'


// vueインスタンスを作成
var vm = new Vue ({
    el: "#shop",
    data: {
        regionList: region,
        citysList: {},
        prefecture: '',
        selectedCitys: {},
        selectedCity: '',
    },
    created: function () {

        //cityList作成
        var self = this;
        this.regionList.map(function (number) {
            Object.assign(self.citysList, { [number.name]: number.city })
        })
    },
    watch: {
      prefecture: function(prefecture) {

        //都道府県が変更されたら、そこに所属しているcityをセット
        this.selectedCitys = this.citysList[prefecture]
        this.selectedCity = ''
      },
      selectedCity: function(selectedCity) {

        window.location.href = './shop/?prefucture='+ this.prefecture +'&city='+ this.selectedCity;
      }
    },
})
